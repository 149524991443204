/*CamBay*/
@font-face {
  font-family: 'Glitten';
  src: url('./Glitten/Glitten-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* RaleWay */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  font-weight: 100;
  src: url('./Raleway/Raleway-Thin.woff2') format('woff2'), url('./Raleway/Raleway-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 200;
  src: url('./Raleway/Raleway-ExtraLight.woff2') format('woff2'), url('./Raleway/Raleway-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 300;
  src: url('./Raleway/Raleway-Light.woff2') format('woff2'), url('./Raleway/Raleway-Light.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  src: url('./Raleway/Raleway-Regular.woff2') format('woff2'), url('./Raleway/Raleway-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 500;
  src: url('./Raleway/Raleway-Medium.woff2') format('woff2'), url('./Raleway/Raleway-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 600;
  src: url('./Raleway/Raleway-SemiBold.woff2') format('woff2'), url('./Raleway/Raleway-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  src: url('./Raleway/Raleway-Bold.woff2') format('woff2'), url('./Raleway/Raleway-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 800;
  src: url('./Raleway/Raleway-ExtraBold.woff2') format('woff2'), url('./Raleway/Raleway-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 900;
  src: url('./Raleway/Raleway-Black.woff2') format('woff2'), url('./Raleway/Raleway-Black.woff') format('woff');
}

/*CamBay*/
@font-face {
  font-family: 'Cambay';
  src: url('./Cambay/Cambay-BoldItalic.eot');
  src: local('Cambay Bold Italic'), local('Cambay-BoldItalic'),
  url('./Cambay/Cambay-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./Cambay/Cambay-BoldItalic.woff2') format('woff2'),
  url('./Cambay/Cambay-BoldItalic.woff') format('woff'),
  url('./Cambay/Cambay-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Cambay';
  src: url('./Cambay/Cambay-Bold.eot');
  src: local('Cambay Bold'), local('Cambay-Bold'),
  url('./Cambay/Cambay-Bold.eot?#iefix') format('embedded-opentype'),
  url('./Cambay/Cambay-Bold.woff2') format('woff2'),
  url('./Cambay/Cambay-Bold.woff') format('woff'),
  url('./Cambay/Cambay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cambay';
  src: url('./Cambay/Cambay-Regular.eot');
  src: local('Cambay Regular'), local('Cambay-Regular'),
  url('./Cambay/Cambay-Regular.eot?#iefix') format('embedded-opentype'),
  url('./Cambay/Cambay-Regular.woff2') format('woff2'),
  url('./Cambay/Cambay-Regular.woff') format('woff'),
  url('./Cambay/Cambay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cambay';
  src: url('./Cambay/Cambay-Italic.eot');
  src: local('Cambay Italic'), local('Cambay-Italic'),
  url('./Cambay/Cambay-Italic.eot?#iefix') format('embedded-opentype'),
  url('./Cambay/Cambay-Italic.woff2') format('woff2'),
  url('./Cambay/Cambay-Italic.woff') format('woff'),
  url('./Cambay/Cambay-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/*Montserrat*/
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-Light.eot');
  src: local('Montserrat Light'), local('Montserrat-Light'),
  url('./Montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-Light.woff') format('woff'),
  url('./Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-MediumItalic.eot');
  src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
  url('./Montserrat/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-MediumItalic.woff') format('woff'),
  url('./Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-Thin.eot');
  src: local('Montserrat Thin'), local('Montserrat-Thin'),
  url('./Montserrat/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-Thin.woff') format('woff'),
  url('./Montserrat/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-ExtraLightItalic.eot');
  src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
  url('./Montserrat/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-ExtraLightItalic.woff') format('woff'),
  url('./Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-BoldItalic.eot');
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
  url('./Montserrat/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-BoldItalic.woff') format('woff'),
  url('./Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-SemiBold.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url('./Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-SemiBold.woff') format('woff'),
  url('./Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-ExtraLight.eot');
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
  url('./Montserrat/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-ExtraLight.woff') format('woff'),
  url('./Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-ExtraBoldItalic.eot');
  src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
  url('./Montserrat/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-ExtraBoldItalic.woff') format('woff'),
  url('./Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-Italic.eot');
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
  url('./Montserrat/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-Italic.woff') format('woff'),
  url('./Montserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url('./Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-Bold.woff') format('woff'),
  url('./Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-LightItalic.eot');
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
  url('./Montserrat/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-LightItalic.woff') format('woff'),
  url('./Montserrat/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-BlackItalic.eot');
  src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
  url('./Montserrat/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-BlackItalic.woff') format('woff'),
  url('./Montserrat/Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-SemiBoldItalic.eot');
  src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
  url('./Montserrat/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-SemiBoldItalic.woff') format('woff'),
  url('./Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('./Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-Regular.woff') format('woff'),
  url('./Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('./Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-Medium.woff') format('woff'),
  url('./Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-ExtraBold.eot');
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
  url('./Montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-ExtraBold.woff') format('woff'),
  url('./Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-Black.eot');
  src: local('Montserrat Black'), local('Montserrat-Black'),
  url('./Montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-Black.woff') format('woff'),
  url('./Montserrat/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-ThinItalic.eot');
  src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
  url('./Montserrat/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('./Montserrat/Montserrat-ThinItalic.woff') format('woff'),
  url('./Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/*CampTon*/

@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-ExtraBoldItalic.eot');
  src: local('Campton-ExtraBoldItalic'),
  url('./Campton/Campton-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-ExtraBoldItalic.woff') format('woff'),
  url('./Campton/Campton-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-BoldItalic.eot');
  src: local('Campton-BoldItalic'),
  url('./Campton/Campton-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-BoldItalic.woff') format('woff'),
  url('./Campton/Campton-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Campton-Book';
  src: url('./Campton/Campton-Book.eot');
  src: local('Campton-Book'),
  url('./Campton/Campton-Book.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-Book.woff') format('woff'),
  url('./Campton/Campton-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-ExtraLight.eot');
  src: local('Campton-ExtraLight'),
  url('./Campton/Campton-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-ExtraLight.woff') format('woff'),
  url('./Campton/Campton-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-MediumItalic.eot');
  src: local('Campton-MediumItalic'),
  url('./Campton/Campton-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-MediumItalic.woff') format('woff'),
  url('./Campton/Campton-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Campton-BookItalic';
  src: url('./Campton/Campton-BookItalic.eot');
  src: local('Campton-BookItalic'),
  url('./Campton/Campton-BookItalic.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-BookItalic.woff') format('woff'),
  url('./Campton/Campton-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-SemiBold.eot');
  src: local('Campton-SemiBold'),
  url('./Campton/Campton-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-SemiBold.woff') format('woff'),
  url('./Campton/Campton-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-Bold.eot');
  src: local('Campton-Bold'),
  url('./Campton/Campton-Bold.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-Bold.woff') format('woff'),
  url('./Campton/Campton-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-Medium.eot');
  src: local('Campton-Medium'),
  url('./Campton/Campton-Medium.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-Medium.woff') format('woff'),
  url('./Campton/Campton-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-SemiBoldItalic.eot');
  src: local('Campton-SemiBoldItalic'),
  url('./Campton/Campton-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-SemiBoldItalic.woff') format('woff'),
  url('./Campton/Campton-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-ThinItalic.eot');
  src: local('Campton-ThinItalic'),
  url('./Campton/Campton-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-ThinItalic.woff') format('woff'),
  url('./Campton/Campton-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-Light.eot');
  src: local('Campton-Light'),
  url('./Campton/Campton-Light.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-Light.woff') format('woff'),
  url('./Campton/Campton-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-Black.eot');
  src: local('Campton-Black'),
  url('./Campton/Campton-Black.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-Black.woff') format('woff'),
  url('./Campton/Campton-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-ExtraLightItalic.eot');
  src: local('Campton-ExtraLightItalic'),
  url('./Campton/Campton-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-ExtraLightItalic.woff') format('woff'),
  url('./Campton/Campton-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-ExtraBold.eot');
  src: local('Campton-ExtraBold'),
  url('./Campton/Campton-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-ExtraBold.woff') format('woff'),
  url('./Campton/Campton-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-Thin.eot');
  src: local('Campton-Thin'),
  url('./Campton/Campton-Thin.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-Thin.woff') format('woff'),
  url('./Campton/Campton-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-BlackItalic.eot');
  src: local('Campton-BlackItalic'),
  url('./Campton/Campton-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-BlackItalic.woff') format('woff'),
  url('./Campton/Campton-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Campton';
  src: url('./Campton/Campton-LightItalic.eot');
  src: local('Campton-LightItalic'),
  url('./Campton/Campton-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./Campton/Campton-LightItalic.woff') format('woff'),
  url('./Campton/Campton-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}



