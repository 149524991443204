/*global*/
.wrapper {
  width: var(--site-width);
  max-width: var(--site-max-width);
  margin: 0 auto;
}
.page-p {
  margin-bottom: clamp(40px, 4.1677vw, 80px);
}
.standard-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(24px, 2.08vw, 40px);
}
@media (max-width: 1025px) {
  .standard-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .standard-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
.error-message {
  font-family: var(--font-Cambay), sans-serif;
  color: var(--color-error);
  font-size: 0.875rem;
  line-height: 1.5;
}
/*form*/
.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 2px 0;
}
.form-error {
  margin: 10px auto;
  font-size: 12px;
  text-align: center;
  color: var(--color-error);
}
.input-form {
  position: relative;
  font-family: var(--font-Montserrat), sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  background: var(--background-color-input);
  border-bottom: 1px solid var(--color-grey-border);
  border-radius: var(--border-radius);
  padding: 1rem 0.875rem;
  color: var(--color-grey);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 100%;
}
.input-form:disabled {
  opacity: 1 !important;
}
.input-form::placeholder {
  color: var(--color-grey);
  font-weight: 300;
}
.input-title {
  font-family: var(--font-Cambay), sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-grey);
}
.form-title {
  font-size: 30px;
  font-weight: 400;
  color: #4e4439;
}
.error-validate {
  color: var(--color-error);
  padding-left: 0.875rem;
  font-size: 0.625rem;
}
/*back-loading*/
.loading-fon {
  overflow: hidden;
}
.loading-fon:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #e7e7e7;
  border-radius: var(--border-radius);
}
.loading-fon:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 70%;
  height: 150%;
  background: linear-gradient(90deg, rgba(252,208,152,0) 0%, rgba(252,208,152,0) 20%, rgba(255, 255, 255, 0.62) 50%, rgba(252,208,152,0) 80%, rgba(252,208,152,0) 100%);
  transform: translateY(-50%) rotate(20deg);
  animation: glare_animate 1.5s ease-in-out infinite;
}
@keyframes glare_animate {
  0% {
    left: -100%;
  }
  100% {
    left: 200%;
  }
}