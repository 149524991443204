:root {
  /* Breakpoints */
  --site-breakpoint-mobile: 768px;

  --site-max-width: 1402px;
  --site-max-width-wrapper: 1920px;
  --site-width: 92vw;

  --site-horizontal-padding: 4vw;

  /* Colors */
  --color-main: #E89732;
  --color-grey: rgba(0, 0, 0, 0.50);
  --color-grey-border: rgb(221, 221, 221);

  --color-checkbox-btn: #bebebe;

  --heading-color: #21323c;
  --paragraph-color: #4e4439;

  --color-footer: #21323c;
  --color-error: #D72638;
  --color-white: #FFFFFF;
  --color-form: #f4f4f4a8;
  --modal-fon: rgba(0, 0, 0, 0.4);
  --color-selector-item: rgba(0, 0, 0, 0.3);

  /* Bg */
  --background-color-input: #F1F1F166;

  /* Fonts */
  --font-Cambay: Cambay;
  --font-Montserrat: Montserrat;
  --font-Raleway: Raleway;
  --font-Campton: Campton;

  --font-Glitten: Glitten, sans-serif;


  /* Misc */
  --border-radius: 8px;

  /*banner-padding*/
  --search-form-padding-horizontal: 20px;
  --search-form-padding-vertical: 15px;
  --search-form-width-button: 115px;

  --padding-book: clamp(15px, 1.04167vw, 20px);
}
@media (max-width: 768px) {
  :root {
    --search-form-padding-horizontal: 15px;
    --search-form-padding-vertical: 10px;
  }
}